import { OrganizationJsonLd } from 'next-seo';
import Image from 'next/image';
import Link from 'next/link';
import { baseUrl } from '@/lib/constants';

export default function ContactFooter() {
  return (
    <>
      <OrganizationJsonLd
        name={process.env.NEXT_PUBLIC_APP_NAME as string}
        url={baseUrl}
        logo={`${baseUrl}/static/icons/Logo.svg`}
        sameAs={[
          'https://www.linkedin.com/company/ahamove/',
          'https://www.facebook.com/AhamoveVietNam',
          'https://www.youtube.com/@ahamoveofficial6703',
          'https://www.tiktok.com/@ahamove.official',
        ]}
        address={{
          '@type': 'PostalAddress',
          streetAddress:
            'Tầng 1, Tòa nhà Rivera Park, 7/28 Thành Thái, Phường 14, Quận 10',
          addressRegion: 'Quận 10',
          addressLocality: 'Hồ Chí Minh',
          addressCountry: 'VN',
          postalCode: '700000',
        }}
        contactPoint={[
          {
            telephone: '1900545411',
            contactType: 'customer service',
            areaServed: ['VN', 'US'],
            availableLanguage: ['Vietnamese', 'English'],
            email: 'support@ahamove.com',
          },
        ]}
      />
      <div className="desktop:space-y-6 space-y-4">
        <h2 className="text-subtitle18 desktop:text-title24 desktop:font-bold text-left font-semibold text-black">
          Công ty Cổ Phần Dịch Vụ Tức Thời
        </h2>
        <ul className="text-body14 desktop:space-y-3 desktop:text-body16 space-y-4 text-left font-medium text-black">
          <li>
            <Link
              href="https://www.google.com/maps/place/C%C4%83n+H%E1%BB%99+Rivera+Park+S%C3%A0i+G%C3%B2n/@10.7694354,106.6614978,17z/data=!3m1!4b1!4m5!3m4!1s0x317528ca8aab0255:0x4b47df7371d378c9!8m2!3d10.7694216!4d106.6636905?hl=vi"
              className="hover:text-neutral-70 desktop:items-center flex space-x-3"
              title=""
            >
              <div className="relative inline-block h-5 w-5 shrink-0">
                <Image
                  src="/static/icons/LocationMarker.svg"
                  alt="VP TPHCM"
                  fill
                />
              </div>
              <p className="inline-block">
                VP TPHCM: Tầng 1, Tòa nhà Rivera Park, 7/28 Thành Thái, Phường
                14, Quận 10
              </p>
            </Link>
          </li>
          <li>
            <Link
              href="https://www.google.com/maps/place/Chung+c%C6%B0+Mipec+T%C3%A2y+S%C6%A1n/@21.0058293,105.8209466,17z/data=!4m9!1m2!2m1!1zVMOyYSBuaMOgIE1pcGVjLCBz4buRIDIyOSBUw6J5IFPGoW4sIFBoxrDhu51uZyBOZ8OjIFTGsCBT4bufLCBRdeG6rW4gxJDhu5FuZyDEkGE!3m5!1s0x3135ab9dcd6b894b:0xc70c638ec4f545aa!8m2!3d21.0054388!4d105.8241089!15sClBUw7JhIG5ow6AgTWlwZWMsIHPhu5EgMjI5IFTDonkgU8ahbiwgUGjGsOG7nW5nIE5nw6MgVMawIFPhu58sIFF14bqtbiDEkOG7kW5nIMSQYVpPIk10w7JhIG5ow6AgbWlwZWMgc-G7kSAyMjkgdMOieSBzxqFuIHBoxrDhu51uZyBuZ8OjIHTGsCBz4bufIHF14bqtbiDEkeG7kW5nIMSRYZIBEmFwYXJ0bWVudF9idWlsZGluZ5oBJENoZERTVWhOTUc5blMwVkpRMEZuU1VSTGVXOVBjelJCUlJBQg?hl=vi"
              className="hover:text-neutral-70 desktop:items-center flex flex-nowrap space-x-3"
              title=""
            >
              <div className="relative inline-block h-5 w-5 shrink-0">
                <Image
                  src="/static/icons/LocationMarker.svg"
                  alt="VP Hà Nội"
                  fill
                />
              </div>
              <p className="inline-block">
                VP Hà Nội: Tầng 9, Tòa nhà Mipec, số 229 Tây Sơn, Phường Ngã Tư
                Sở, Quận Đống Đa
              </p>
            </Link>
          </li>
          <li>
            <Link
              href="https://maps.app.goo.gl/8zGKTRTSWrDfY2N89"
              className="hover:text-neutral-70 desktop:items-center flex space-x-3"
              title=""
            >
              <div className="relative inline-block h-5 w-5 shrink-0">
                <Image
                  src="/static/icons/LocationMarker.svg"
                  alt="VP Đà Nẵng"
                  fill
                />
              </div>
              <p className="inline-block">
                VP Đà Nẵng: 66A Lê Đình Lý, Thanh Khê, Đà Nẵng
              </p>
            </Link>
          </li>
          <li>
            <Link
              href="mailto:support@ahamove.com"
              className="hover:text-neutral-70 desktop:items-center flex flex-nowrap space-x-3"
              title=""
            >
              <div className="relative inline-block h-5 w-5 shrink-0">
                <Image src="/static/icons/Mail.svg" alt="Email" fill />
              </div>
              <p className="inline-block">support@ahamove.com</p>
            </Link>
          </li>
          <li>
            <Link
              href="tel:1900545411"
              className="hover:text-neutral-70 desktop:items-center flex flex-nowrap space-x-3"
              title=""
            >
              <div className="relative inline-block h-5 w-5 shrink-0">
                <Image src="/static/icons/Phone.svg" alt="Phone" fill />
              </div>
              <p className="inline-block">1900545411</p>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
